export const PersonalData = [
  <p className="text-[#8f35ff]">{"{"}</p>,
  <p className={"pl-[50px] w-[80%]"}>
    {" "}
    personal info: <span className="text-[#8f35ff]">{"{"}</span>
  </p>,
  <p className={"pl-[100px] w-[80%]"}>name: Aryaan Bhimani,</p>,
  <p className={"pl-[100px] w-[80%]"}>
    status: Writing solidity <a href="https://sloika.xyz/">@sloika</a>,
  </p>,
  <p className={"pl-[100px] w-[80%]"}>
    bio: Finding &alpha;lpha on Twitter and learning how to extract maximal
    value from Ethereum,
  </p>,
  <p className={"pl-[100px] w-[80%]"}>
    location: <span className="text-[#8f35ff]">{"{"}</span>
  </p>,
  <p className={"pl-[150px] w-[80%]"}>country: Canada,</p>,
  <p className={"pl-[150px] w-[80%]"}>city: Toronto</p>,
  <p className={"pl-[100px] w-[80%]"}>
    <span className="text-[#8f35ff]">{"}"}</span>,
  </p>,
  <p className={"pl-[100px] w-[80%]"}>
    contact me: <span className="text-[#8f35ff]">{"{"}</span>
  </p>,
  <p className={"pl-[150px] w-[80%] break-words"}>
    email:{" "}
    <a href="mailto:aryaan082@gmail.com" target={"_blank"} rel="noreferrer">
      aryaan.ab.bhimani@gmail.com
    </a>
    ,
  </p>,
  <p className={"pl-[150px] w-[80%]"}>
    github:{" "}
    <a href="https://github.com/Aryaan082" target={"_blank"} rel="noreferrer">
      aryaan082
    </a>
    ,
  </p>,
  <p className={"pl-[150px] w-[80%]"}>
    twitter:{" "}
    <a href="https://twitter.com/aryaan082" target={"_blank"} rel="noreferrer">
      @aryaan082
    </a>
  </p>,
  <p className={"pl-[100px] w-[80%] text-[#8f35ff]"}>{"}"}</p>,
  <p className={"pl-[50px] w-[80%]"}>
    <span className="text-[#8f35ff]">{"}"}</span>,
  </p>,
  <p className={"pl-[50px] w-[80%]"}>
    my links: <span className="text-[#8f35ff]">{"{"}</span>
  </p>,
  <p className={"pl-[100px] w-[80%]"}>
    sloika: Updating contracts and adding features{" "}
    <a href="https://sloika.xyz/" target={"_blank"} rel="noreferrer">
      @sloika
    </a>
    ,
  </p>,
  <p className={"pl-[100px] w-[80%]"}>
    echooo: Building on-chain instant messaging{" "}
    <a href="https://demo.echooo.app/" target={"_blank"} rel="noreferrer">
      @echooo
    </a>
    ,
  </p>,
  <p className={"pl-[100px] w-[80%]"}>
    open games: Building on-chain blackjack{" "}
    <a
      href="https://opengames-15140.firebaseapp.com/"
      target={"_blank"}
      rel="noreferrer"
    >
      @opengames
    </a>
  </p>,
  <p className={"pl-[50px] w-[80%]"}>
    <span className="text-[#8f35ff]">{"}"}</span>
  </p>,
  <p className="text-[#8f35ff]">{"}"}</p>,
  "",
];

export default PersonalData;
